import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Global, css } from '@emotion/react';

ReactDOM.render(
  <React.StrictMode>
    <Global
      styles={css`
        body,
        html {
          padding: 0px;
          margin: 0px;
          box-sizing: border-box;
          background-color: #232528;
          color: #ffffff;
        }

        * {
          font-family: --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      `}
    />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
