import { css } from '@emotion/react';
import Navigation from './components/navigation';

const User = () => (
  <section
    css={css`
      display: grid;
      grid-template-columns: 1fr auto 3fr;
      gap: 2rem;
      padding: 2rem;
    `}>
    <div>
      <div>olympics</div>
      <div>world-cup</div>
      <div>national-championship</div>
    </div>
    <div
      css={css`
        width: fit-content;
      `}>
      <img
        src='https://picsum.photos/250/250'
        alt='thingy'
        css={css`
          border-radius: 50%;
          border: solid 3px #ff8811ff;
          padding: 3px;
          height: 10rem;
          width: 10rem;
        `}
      />
    </div>
    <div>
      <h2>Dishant Mishra</h2>
      <p
        css={css`
          font-size: 0.8rem;
          line-height: 1.6;
        `}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        ultrices sem eu molestie pharetra. Suspendisse interdum enim ornare
        pretium vulputate. Sed tortor elit, tempus sed erat ut, gravida pretium
        nisl. Mauris aliquam scelerisque facilisis. Maecenas eu pharetra orci.
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae.
      </p>
    </div>
  </section>
);

const App = () => {
  return (
    <div css={css``}>
      <Navigation />
      <User />
    </div>
  );
};

export default App;
