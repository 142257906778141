import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCog } from '@fortawesome/free-solid-svg-icons';

const Navigation = () => {
  return (
    <nav
      css={css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        height: 5rem;
        line-height: 5rem;
        font-size: 2rem;
        padding: 0 1.5rem;
        background-color: transparent;
        backdrop-filter: brightness(50%);
      `}>
      <div>
        <FontAwesomeIcon icon={faCog} />
      </div>
      <div></div>
      <div>
        <FontAwesomeIcon icon={faBars} />
      </div>
    </nav>
  );
};

export default Navigation;
